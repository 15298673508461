@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

iframe
{
    display: none;
}